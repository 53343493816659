<template>
  <div class="my-3 mx-4">
    <TenantAlert />
    <LocationAlert
      :locationId="+reservation.locationId"
      v-if="reservation.locationId"
    />
    <div class="row">
      <div class="col-12 summary">
        <div class="heading">Assign Primary Occupant to Reservation</div>

        <br />
        <AdminReservationInfo />
        <FormErrorAlert
          v-if="errors.length > 0"
          :errors="errors"
          :formRef="this.$refs.customerSearch"
        />
        <ValidationObserver ref="customerSearch">
          <div class="card" v-if="this.showCustomerSearch">
            <div class="card-header">
              <h4 class="card-title mt-2 mb-0">Find Existing Customer</h4>
            </div>
            <div class="card-body">
              <form @submit.prevent="onSubmit">
                <div class="row mb-2">
                  <div class="col-md-6">
                    <TextInput
                      rules="min:2|customerName|max:30"
                      name="First Name"
                      id="searchFirstName"
                      v-model="searchCriteria.firstName"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      rules="min:2|customerName|max:30"
                      name="Last Name"
                      id="searchLastName"
                      v-model="searchCriteria.lastName"
                    />
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-md-4">
                    <TextInput
                      rules="min:3|max:256"
                      name="Email"
                      id="searchEmail"
                      v-model="searchCriteria.email"
                    />
                  </div>
                  <div class="col-md-4">
                    <TextInput
                      rules="maskNumMin:3"
                      name="Phone"
                      id="searchPhone"
                      v-model="searchCriteria.phone"
                      mask="(###) ###-####"
                      placeholder="(___) ___-____"
                    />
                  </div>
                  <div class="col-md-4">
                    <TextInput
                      rules="min:3"
                      name="Zip"
                      id="searchZip"
                      v-model="searchCriteria.zip"
                      placeholder="_____-____"
                    />
                  </div>
                </div>
                <button type="submit" class="btn btn-lg btn-primary mt-4 mr-2">
                  Search
                </button>
                <button
                  type="button"
                  class="btn btn-cancel btn-lg mr-2 mt-4"
                  @click="resetForm"
                >
                  Reset
                </button>
                <button
                  v-if="this.showSearchResults"
                  role="button"
                  class="btn btn-lg btn-success mr-2 mt-4"
                  @click="showCustomerAdd = !showCustomerAdd"
                >
                  Add New Customer Profile
                </button>
              </form>
            </div>
          </div>
        </ValidationObserver>
        <div
          class="card p-3"
          v-if="this.showSearchResults && !this.showCustomerAdd"
        >
          <div class="card-body">
            <div class="d-flex mb-2">
              <div class="align-self-center">Items Per Page:</div>
              <div class="ml-2">
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                  aria-label="Items Per Page"
                ></b-form-select>
              </div>
            </div>
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="loadData"
              responsive
              no-local-sorting
              :current-page="currentPage"
              :per-page="perPage"
              small
              bordered
              sort-icon-left
            >
              <template v-slot:cell(address)="data">
                {{ data.item.street1 }}
                <br />
                {{ data.item.street2 }}
              </template>
              <template v-slot:cell(zip)="data">
                {{ data.item.zip | zipCode }}
              </template>
              <template v-slot:cell(comments)="data">
                <div v-if="data.item.comments">
                  {{ data.item.comments.substr(0, 50) }}
                </div>

                <b-collapse
                  :id="'comments' + data.item.id"
                  v-if="data.item.comments && data.item.comments.length >= 50"
                >
                  {{
                    data.item.comments.substring(
                      50,
                      data.item.comments.length - 1
                    )
                  }}
                  <br />
                </b-collapse>
                <b-button
                  v-if="data.item.comments && data.item.comments.length >= 50"
                  v-b-toggle="'comments' + data.item.id"
                  variant="link"
                  ><span class="when-open text-primary">Show less</span
                  ><span class="when-closed text-primary">Show more...</span>
                </b-button>
              </template>
              <template v-slot:cell(id)="data">
                <button
                  class="btn btn-primary btn-sm"
                  @click="setPrimaryOccupant(data.item.id)"
                >
                  Set As Primary Occupant
                </button>
              </template>
            </b-table>
            <div class="d-flex">
              <div class="mr-auto" v-if="totalRows > 0">
                Showing
                {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }}
                to {{ perPage * currentPage - perPage + perPageDisplay }} of
                {{ totalRows }} entries
              </div>
              <div class="mr-auto" v-if="totalRows === 0">No Results Found</div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
        <div
          class="card p-3"
          v-if="this.showCustomerAdd && this.showCustomerSearch"
        >
          <div class="card-header">
            <h4 class="card-title mt-2 mb-0">Add New Customer Information</h4>
          </div>
          <div class="card-body">
            <ValidationObserver ref="addCustomerForm">
              <form @submit.prevent="creatCustomer">
                <AdminCustomerAddForm
                  :customer="customer"
                  :usernameIsAvailable="
                    !invalidUsername &&
                      this.customer.username &&
                      this.customer.username !== ''
                  "
                  :usernameIsNotAvailable="
                    usernameChecked && !usernameAvailable
                  "
                />
                <button
                  type="submit"
                  class="btn btn-lg btn-primary mt-4 mr-2"
                  :disabled="invalidUsername"
                >
                  Save
                </button>
                <button
                  class="btn btn-cancel btn-lg mt-4"
                  @click="showCustomerAdd = !showCustomerAdd"
                  type="button"
                >
                  Cancel
                </button>
                <p class="mt-2">
                  <span style="color: red;">*</span> Indicates a required field
                </p>
              </form>
            </ValidationObserver>
          </div>
        </div>
        <button
          type="button"
          @click="showCustomerSearch = !showCustomerSearch"
          class="btn btn-lg btn-primary mr-2 mb-2 float-left"
          v-if="!this.showCustomerSearch"
        >
          Assign Primary Occupant
        </button>
        <button
          type="button"
          @click="goBack"
          class="btn btn-lg btn-secondary mr-2 mb-2 float-left"
        >
          Back
        </button>
        <button
          type="button"
          @click="goHome"
          class="btn btn-cancel btn-lg mr-2 mb-2 float-left"
        >
          Cancel
        </button>
      </div>
    </div>
    <AdminOverrideAlertModal
      @override="overrideBusinessRule"
      header="Do you wish to override this business rule?"
    />
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import TextInput from "@/validation/TextInput.vue";
import FormErrorAlert from "@/components/alert/FormErrorAlert.vue";
import AdminProfileService from "@/services/admin/AdminProfileService.js";
import AdminCustomerAddForm from "@/components/admin/AdminCustomerAddForm.vue";
import AdminReservationInfo from "@/components/admin/AdminReservationInfo.vue";
import AdminSpotReservationService from "@/services/admin/AdminSpotReservationService.js";
import TenantAlert from "@/components/alert/TenantAlert.vue";
import LocationAlert from "@/components/alert/LocationAlert.vue";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";
import AdminOverrideAlertModal from "@/components/admin/AdminOverrideAlertModal.vue";
import ProfileService from "@/services/ProfileService.js";

export default {
  name: "AdminReservationAddOccupant",
  title: "Admin - Reservation Add Occupant",
  mixins: [checkPermissionMixin],
  components: {
    ValidationObserver,
    TextInput,
    FormErrorAlert,
    AdminCustomerAddForm,
    AdminReservationInfo,
    TenantAlert,
    LocationAlert,
    AdminOverrideAlertModal
  },
  data() {
    return {
      errors: [],
      searchCriteria: {},
      pricing: "",
      selectedCustomerId: null,
      showCustomerSearch: false,
      showCustomerAdd: false,
      showSearchResults: false,
      usernameAvailable: false,
      usernameChecked: false,
      loadingUsername: false,
      usernameTimeout: null,
      fields: [
        { key: "firstName", sortable: true },
        { key: "lastName", sortable: true },
        { key: "emailAddress", sortable: true },
        { key: "address", sortable: true },
        { key: "city", sortable: true },
        { key: "state", sortable: true },
        { key: "zip", sortable: true },
        {
          key: "phone",
          sortable: true,
          formatter: value => {
            return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(
              6
            )}`;
          }
        },
        { key: "comments", class: "commentsColumn" },
        { key: "id", label: "Actions/Options" }
      ],
      currentPage: 1,
      totalRows: 25,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      perPageDisplay: 25,
      customer: {
        country: "US"
      }
    };
  },
  methods: {
    goHome() {
      this.$router.push("/admin").catch(() => {});
    },
    goBack() {
      this.$router.push("/admin/reservation-add").catch(() => {});
    },
    async checkUsername(username) {
      if (
        username &&
        username.match(/^[A-Za-z0-9-_=.]+$/) &&
        username.length > 7 &&
        username.length < 21
      ) {
        const profileService = new ProfileService(this.tenantId);
        this.loadingUsername = true;
        this.usernameAvailable = false;
        this.usernameChecked = false;
        const response = await profileService.checkUsernameAvailability(
          username
        );
        this.usernameAvailable =
          response.statusCode === "Success" && response.data === true;
        this.usernameChecked = true;
        this.loadingUsername = false;
      } else {
        this.usernameAvailable = this.usernameChecked = false;
      }
    },
    onSubmit() {
      this.$refs.customerSearch.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.customerSearch.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.customerSearch.refs[errors[0]["key"]].$el.scrollIntoView(
              {
                behavior: "smooth",
                block: "center"
              }
            );
          }, 100);
        } else {
          this.errors = [];
          if (this.showSearchResults == true) {
            //If already visible, refresh in case search params changed
            this.$refs.table?.refresh();
          }
          this.showSearchResults = true;
        }
      });
    },
    resetForm() {
      this.errors = [];
      this.searchCriteria = {};
      this.showSearchResults = false;
      this.currentPage = 1;
      this.$refs.customerSearch.reset();
    },
    loadData(ctx) {
      let paging = {
        pageNumber: ctx.currentPage,
        itemsPerPage: ctx.perPage,
        sortField: ctx.sortBy,
        sortDescending: ctx.sortDesc
      };
      const profileService = new AdminProfileService(this.tenantId);
      this.searchCriteria.zip = this.searchCriteria.zip ?? null;
      this.searchCriteria.phone = this.searchCriteria.phone ?? null;
      return profileService
        .search(this.searchCriteria, paging)
        .then(response => {
          this.currentPage = response.searchResults.pageNumber;
          this.totalRows = response.searchResults.totalItems;
          this.perPageDisplay = response.searchResults.itemsPerPage;
          return response.searchResults.data;
        });
    },
    async creatCustomer() {
      this.$refs.addCustomerForm.validate().then(async success => {
        if (!success) {
          setTimeout(() => {
            const errors = Object.entries(this.$refs.addCustomerForm.errors)
              .map(([key, value]) => ({ key, value }))
              .filter(error => error["value"].length);
            this.errors = errors;
            this.$refs.addCustomerForm.refs[
              errors[0]["key"]
            ].$el.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          }, 100);
        } else {
          this.errors = [];
          try {
            this.$store.commit("auth/setLoading", true);
            const profileService = new AdminProfileService(this.tenantId);
            let createUser = false;
            this.customer.zipCode = this.customer.zipCode ?? null;
            this.customer.primaryPhoneNumber =
              this.customer.primaryPhoneNumber ?? null;
            this.customer.alternatePhoneNumber =
              this.customer.alternatePhoneNumber ?? null;
            if (!this.customer.comments) {
              this.customer.comments = "None";
            }
            if (this.customer.username && this.customer.username !== "") {
              createUser = true;
            }
            const newCustomerResponse = await profileService.createCustomerProfileAdmin(
              this.customer,
              createUser
            );
            if (newCustomerResponse.statusCode == "Success") {
              const newCustomer = newCustomerResponse.data;
              this.newCustomerSearch(newCustomer);
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-success",
                message: `Customer ${newCustomer.firstName} ${newCustomer.lastName} was successfully added`,
                layer: "admin"
              });
            } else {
              this.$store.commit("alert/setErrorAlert", {
                type: "alert-danger",
                message: "Error in adding customer, please try again",
                layer: "admin"
              });
            }
          } catch (err) {
            this.$store.commit("alert/setErrorAlert", {
              type: "alert-danger",
              message: "Something went wrong...",
              layer: "admin"
            });
          } finally {
            this.$store.commit("auth/setLoading", false);
          }
        }
      });
    },
    newCustomerSearch(newCustomer) {
      this.searchCriteria = {
        firstName: newCustomer.firstName,
        lastName: newCustomer.lastName,
        email: newCustomer.email,
        zip: newCustomer.addresses[0].zipcode,
        phone: newCustomer.customerPhoneNumbers[0].number
      };
      this.showCustomerAdd = false;
      this.showSearchResults = true;
      this.onSubmit();
      this.customer = {};
    },
    async setPrimaryOccupant(id) {
      try {
        this.$store.commit("auth/setLoading", true);
        const spotReservationService = new AdminSpotReservationService(
          this.tenantId
        );
        const response = await spotReservationService.validateSpotReservation({
          spotId: this.reservation.spotId,
          startDate: this.reservation.startDate,
          endDate: this.reservation.endDate,
          lockCode: this.reservation.lockCode,
          customerId: id,
          overrideBusinessRule: this.reservation.overrideBusinessRule
        });
        if (response?.statusCode == "Failure") {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: response.messages[0],
            layer: "admin"
          });
          if (
            this.checkPermission("ReservationManagementOverrideOnBusinessRules")
          ) {
            this.$bvModal.show("override-modal");
            this.selectedCustomerId = id;
          }
        } else {
          this.reservation.customerId = id;
          this.$store.commit("transaction/setReservation", this.reservation);
          this.$router.push("/admin/reservation-add-details").catch(() => {});
        }
      } catch (e) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$store.commit("auth/setLoading", false);
      }
    },
    overrideBusinessRule() {
      this.$store.dispatch("alert/clear");
      this.reservation.overrideBusinessRule = true;
      this.setPrimaryOccupant(this.selectedCustomerId);
    }
  },
  watch: {
    "customer.username"() {
      clearTimeout(this.usernameTimeout);
      var self = this;
      this.usernameTimeout = setTimeout(() => {
        self.checkUsername(self.customer.username);
      }, 1000);
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    reservation() {
      return this.$store.getters["transaction/reservation"];
    },
    invalidUsername() {
      return this.customer.username
        ? this.customer.username === ""
          ? false
          : !(this.usernameAvailable && this.usernameChecked)
        : false;
    }
  },
  created() {
    this.$store.commit("auth/setLoading", false);
  }
};
</script>

<style scoped>
.label {
  font-weight: bold;
  display: inline-block;
  margin-bottom: 0.5rem;
}
.heading {
  font-size: x-large;
}
.summary {
  min-height: 510px;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.commentsColumn {
  width: 300px !important;
}
</style>
